<script>
import EditorialTagNewView from '@/views/tag/editorialTags/EditorialTagsNewView.vue'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'EditorialTagEditView',
  extends: EditorialTagNewView,
  methods: {
    async getTag () {
      try {
        await this.$store.dispatch('tag/fetchOne', this.$route.params.id)
        const fetchedTag = this.$store.getters['tag/detail']
        if (fetchedTag.description === null) {
          fetchedTag.description = ''
        }
        fetchedTag.imageId = fetchedTag.image?.id // added imageId based on image object from core
        this.tag = fetchedTag
        if (Array.isArray(fetchedTag.relatedArticlesIds) && fetchedTag.relatedArticlesIds.length > 0) {
          this.relatedArticles = await this.$store.dispatch('tag/fetchArticles', fetchedTag.relatedArticlesIds)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('tag/update', { record: this.tag })
        .then(() => {
          this.tag = this.$store.getters['tag/detail']
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            this.$router.push('/commonTags')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.showEditComponents = true
    this.getTag()
  }
}
</script>
